import React from "react";
import "./App.css";
import YourCheckoutComponent from "./YourCheckoutComponent";

function App() {
  return (
    <div className="App">
      <div className="container">
        {/* Left Side: GIF */}
        <div className="gif-container">
          <img 
            src="/gif.gif" 
            alt="Demo GIF" 
            className="responsive-gif"
          />
        </div>

        {/* Right Side: Checkout */}
        <div className="checkout-container">
          <h1>LustCord Membership</h1>
          <p>Click below to make a payment:</p>
          <YourCheckoutComponent />
        </div>
      </div>
    </div>
  );
}

export default App;
