import React from "react";

import {HelioCheckout} from '@heliofi/checkout-react'

const helioConfig = {
    paylinkId: "675228ea2886483a674a5fc3",
    theme: {"themeMode":"light"},
    display: "inline",
    onSuccess: event => console.log(event),
    onError: event => console.log(event),
    onPending: event => console.log(event),
    onCancel: () => console.log("Cancelled payment"),
    onStartPayment: () => console.log("Starting payment"),
};

function YourCheckoutComponent() {
  return <HelioCheckout config={helioConfig} />;
}
      

export default YourCheckoutComponent;
